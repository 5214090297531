/* src/ImageLayout.css */
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    /* border: 1px solid red; */
    width: 65%;
    margin: 2rem auto;
}

.image-item {
    position: relative;
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .1s ease;
    backface-visibility: hidden;
    /* background: rgba(0, 0, 255, 0.5); */
    background: rgba(0, 0, 139, 1);
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-item.tall {
    grid-row: span 2;
}

.text-content {
    text-align: center;
}

.text-content h2 {
    margin: 0;
}

.text-content p {
    margin: 5px 0;
}

.text-content button {
    margin: 5px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.text-content button:hover {
    opacity: 0.8;
}

.middle {
    transition: .9s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.image-item:hover .image {
    opacity: 0.5;
}

.image-item:hover .middle {
    opacity: 1;
}

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }