.test-container {
	/* display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 100%;
	align-items: center;
	min-height: 100vh;
	overflow: hidden; */
	/* border: 3px solid red; */
	/* gap: 1rem; */
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;
	width: 100%;
	justify-items: center;
	margin-top: 1rem;
	align-items: center;
}
@media (max-width: 768px) {
	.test-container {
		grid-template-columns: 1fr; /* Switches to one column on small screens */
	}
}

.testimonial {
	flex: 1 1 calc(40%);
	margin: 2rem 1rem 2rem 1rem;
	/* width: 500px; */
	max-width: 100%;
	background: #ffca52;
	padding: 4em 3em;
	display: flex;
	align-items: flex-end;
	position: relative;
	box-shadow: 0 2px 2px hsl(0deg 0% 0%/0.075), 0 3px 3px hsl(0deg 0% 0%/0.075),
		0 5px 5px hsl(0deg 0% 0%/0.075), 0 9px 9px hsl(0deg 0% 0%/0.075),
		0 17px 17px hsl(0deg 0% 0%/0.075);
	position: relative;
	border-radius: 2rem;
	/* &:after {
		content: "";
		border: 8px solid navy;
		border-radius: 50px;
		width: 85%;
		height: 120%;
		position: absolute;
		z-index: -1;
		left: 1.5em;
		top: -2em;
	} */
	/* &:before {
		content: "";
		position: absolute;
		bottom: -6.2em;
		left: 5em;
		z-index: 1;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 70px 100px 0 0;
		border-color: navy transparent transparent transparent;
	} */
	.quote {
		position: absolute;
		font-size: 3em;
		width: 40px;
		height: 40px;
		background: navy;
		color: #fff;
		text-align: center;
		line-height: 1.25;
		&.open {
			top: 0;
			left: 0;
		}
		&.close {
			bottom: 0;
			right: 0;
		}
	}
	p {
		display: inline-block;
		font-weight: bold;
		font-size: 1rem;
	}
	.source {
		width: 40%;
		height: 100%;
		position: relative;
		span {
			display: inline-block;
			font-weight: bold;
			font-size: 1.15em;
			position: relative;
			margin-left: 1rem;
			text-align: right;
			&:before {
				content: "\2014";
				display: inline;
				margin-right: 5px;
			}
		}
	}
	.image {
		transform: rotate(-5deg);
		position: absolute;
		top: 0.5em;
		right: 1.5em;
		img {
			border: 10px solid #fff;
			margin: 0;
			padding: 0;
		}
		.clip {
			border: 2px solid #222;
			border-right: none;
			height: 75px;
			width: 20px;
			position: absolute;
			right: 30%;
			top: -15%;
			border-radius: 25px;
			&:before {
				content: "";
				position: absolute;
				top: -1px;
				right: 0;
				height: 10px;
				width: 16px;
				border: 2px solid #222;
				border-bottom: none;
				border-top-left-radius: 25px;
				border-top-right-radius: 25px;
				z-index: 99;
			}
			&:after {
				content: "";
				position: absolute;
				bottom: -1px;
				right: 0;
				height: 40px;
				width: 16px;
				border: 2px solid #222;
				border-top: none;
				border-bottom-left-radius: 25px;
				border-bottom-right-radius: 25px;
				z-index: 99;
			}
		}
	}
}
