/* Centering and styling the form container */
.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f0f2f5;
	height: 92vh;
	padding: 1rem;
}

.login-form {
	background-color: #fff;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 400px;
}

.login-form h2 {
	margin-bottom: 1.5rem;
	color: #f45905;
	font-size: 1.8rem;
	text-align: center;
}

.login-form label {
	font-size: 0.9rem;
	color: #555;
}

.login-form input {
	width: 100%;
	padding: 0.8rem;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 1rem;
	color: #333;
}

.login-form input:focus {
	outline: none;
	border-color: #4a90e2;
}

.error-message {
	color: #d9534f;
	font-size: 0.8rem;
	margin-top: -0.8rem;
	margin-bottom: 0.8rem;
}

.login-button {
	width: 100%;
	padding: 0.8rem;
	background-color: #4a90e2;
	color: white;
	border: none;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	margin-top: 1rem;
}

.login-button:disabled {
	background-color: #a6c5e2;
	cursor: not-allowed;
}

.login-button:hover:not(:disabled) {
	background-color: #357ab8;
}
