@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: white,
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans', sans-serif;
}

.MuiTablePagination-selectLabel {
  margin-top: 16px;
}

.MuiTablePagination-displayedRows {
  margin-top: 20px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}